import { useRouter } from 'next/router';

import { HomeHeader } from '@/components/Header';
import HomepageCard from '@/components/HomepageCard';
import { HomepageCardVariant } from '@/components/HomepageCard/HomepageCard';
import { StaticRoutes } from '@/utils/links';

const HomepageView = () => {
  const router = useRouter();

  const homepageCards = [
    {
      title: 'Complete your profile',
      buttonLabel: 'Finish up',
      variant: HomepageCardVariant.CompleteYourProfile,
      routeLink: StaticRoutes.Profile,
      onClick: () => router.push(StaticRoutes.Profile),
    },
    {
      title: 'Live Jobs & Training',
      buttonLabel: 'Explore',
      variant: HomepageCardVariant.LiveOpportunities,
      onClick: () => router.push(StaticRoutes.LiveOpportunities),
    },
    {
      title: 'Learning Courses',
      buttonLabel: 'Start now',
      variant: HomepageCardVariant.LearningCourses,
      onClick: () => router.push(StaticRoutes.ExploreLearningCourses),
    },
    {
      title: 'Job Discovery',
      buttonLabel: 'Coming Soon',
      variant: HomepageCardVariant.JobDiscovery,
    },
  ];

  return (
    <main>
      <header>
        <HomeHeader />
      </header>

      {/* Cards section */}
      <div className='grid grid-cols-2 gap-4 justify-between px-4 py-10'>
        {homepageCards.map((card, idx) => (
          <HomepageCard
            key={idx}
            config={{
              title: card.title,
              buttonLabel: card.buttonLabel,
              variant: card.variant,
            }}
            onButtonClick={card.onClick}
          />
        ))}
      </div>
    </main>
  );
};

export default HomepageView;

import clsx from 'clsx';

function LoadingCard({ theme }: { theme: 'light' | 'dark' }) {
  const isDarkTheme = theme === 'dark';

  return (
    <div
      className={`${clsx(
        isDarkTheme ? 'bg-secondary' : 'bg-white',
        'h-full w-full flex justify-center items-center'
      )}`}
    >
      <div className='flex h-1/2 flex-col'>
        <div className='h-36 w-36 mx-auto md:h-64 md:w-64'>
          <img
            src={
              isDarkTheme ? '/logos/white_logo.png' : '/logos/black_logo.png'
            }
            alt='Greenworkx black logo'
            className='object-contain animate-pulse'
          />
        </div>
        <div className='grow' />
        <h1
          className={`${clsx(
            'text-center',
            isDarkTheme ? 'text-white' : 'text-black',
            'text-md  md:text-2xl font-bold max-w-sm md:max-w-md mx-16 sm:mx-6 md:mx-[10%]'
          )}`}
        >
          PEOPLE ARE THE ANSWER TO THE CLIMATE EMERGENCY
        </h1>
      </div>
    </div>
  );
}

export default LoadingCard;

import React from 'react';
import Button from '../Button';

import { useRouter } from 'next/router';

export enum HomepageCardVariant {
  Default = 'default',
  CompleteYourProfile = 'completeYourProfile',
  LiveOpportunities = 'liveOpportunities',
  LearningCourses = 'learningCourses',
  JobDiscovery = 'jobDiscovery',
}

interface HomepageCardConfig {
  title: string;
  buttonLabel: string;
  routeLink?: string;
  variant: HomepageCardVariant;
}

interface HomepageCardProps {
  config: HomepageCardConfig;
  onButtonClick?: () => void;
}

const variantStyles = {
  [HomepageCardVariant.Default]: {
    background: 'bg-white',
    text: 'text-black',
    button: 'bg-black text-white hover:bg-gray-800',
  },
  [HomepageCardVariant.CompleteYourProfile]: {
    background: 'bg-white',
    text: 'text-black',
    button: 'bg-black text-white hover:bg-gray-800',
    badge: 'Priority',
  },
  [HomepageCardVariant.LiveOpportunities]: {
    background: 'bg-white',
    text: 'text-black',
    button: 'bg-black text-white hover:bg-gray-800',
    badge: 'Hiring',
  },
  [HomepageCardVariant.LearningCourses]: {
    background: 'bg-secondary border-secondary',
    text: 'text-white',
    button: 'bg-white text-black hover:bg-gray-800',
  },
  [HomepageCardVariant.JobDiscovery]: {
    background: 'bg-gray-300 border-gray-300',
    text: 'text-black',
    button: 'disabled btn-disabled text-[#858585] bg-white',
  },
};

const badgeLabels: Record<HomepageCardVariant, string | undefined> = {
  [HomepageCardVariant.Default]: undefined,
  [HomepageCardVariant.CompleteYourProfile]: 'Priority',
  [HomepageCardVariant.LiveOpportunities]: 'Hiring',
  [HomepageCardVariant.LearningCourses]: undefined,
  [HomepageCardVariant.JobDiscovery]: undefined,
  // you can define badges here! 👁️👄👁️
};

const HomepageCard: React.FC<HomepageCardProps> = ({
  config,
  onButtonClick,
}) => {
  const router = useRouter();

  const renderBadge = () => {
    const badgeLabel = badgeLabels[config.variant];

    if (!badgeLabel) return undefined;

    return (
      <span className='absolute top-0 right-0 transform translate-x-1/6 -translate-y-1/6 bg-primary text-black py-1 px-3 rounded-tr-xl rounded-bl-xl uppercase text-[8px] sm:text-xs z-10'>
        {badgeLabel}
      </span>
    );
  };

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
    } else {
      console.log('button click handler not provided :O');
    }
  };

  const styles = variantStyles[config.variant];

  const cardContainerClasses = `w-full flex flex-col rounded-2xl border-[3px] border-black px-3 py-2 relative min-h-[175px] ${styles.background} ${styles.text}`;

  const titleClass =
    config.variant === HomepageCardVariant.CompleteYourProfile ||
    config.variant === HomepageCardVariant.LiveOpportunities
      ? 'pr-20'
      : '';

  return (
    <div className={cardContainerClasses}>
      {renderBadge()}

      {/* Card title */}
      <div className='flex flex-col justify-between items-start z-0 gap-2'>
        <h1
          className={`text-lg sm:text-xl font-bold ${titleClass} leading-tight`}
        >
          {config.title}
        </h1>
      </div>

      {/* Card button */}
      <div className='mt-auto flex flex-row items-center justify-between z-0 w-full'>
        <Button
          className={`${styles.button} border-none py-2 mt-4 w-full`}
          onClick={handleButtonClick}
          size='md'
        >
          {config.buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default HomepageCard;

import LoadingCard from '@/components/LoadingCard';
import { useAuthContext } from '@/contexts/AuthContext';
import { Roles } from '@/lib/mongoRealm/consts';
import { StaticRoutes } from '@/utils/links';
import { useRouter } from 'next/router';
import { ComponentType, useState } from 'react';
import { useTimeout } from 'usehooks-ts';

// This is a higher order component that will wrap a component and check if the user is authenticated
// If the user is not authenticated, it will redirect them to the login page
// If the user is authenticated, it will render the wrapped component
// The HOC also accepts an optional array of roles that the user must have to access the wrapped component
function withAuthentication<WrappedComponentProps extends object>(
  WrappedComponent: ComponentType<WrappedComponentProps>,
  rolesAccess: Roles = ['learner']
) {
  return function EnhancedComponent(props: WrappedComponentProps) {
    const [{ isReady }, { logOutUser, getActiveUser, getRoles }] =
      useAuthContext();
    const router = useRouter();

    // There's a delay of 1 second to prevent the loading card from flashing on the screen
    const [isLoadingCardVisible, setIsLoadingCardVisible] = useState(false);
    useTimeout(() => setIsLoadingCardVisible(true), 1000);

    // If the AuthContext is still loading after 10 seconds, trigger timeout
    const [hasAuthenticationTimeout, setHasAuthenticationTimeout] =
      useState(false);
    useTimeout(() => setHasAuthenticationTimeout(true), 10000);

    if (!router.isReady) return;

    if (hasAuthenticationTimeout && !isReady) {
      // If the AuthContext is still loading after 10 seconds, show an error message
      console.error(
        'Authentication timed out after 10 seconds. Please check your internet connection and try again.'
      );
      logOutUser();
      router.push(StaticRoutes.Login);
    }

    if (!isReady) {
      // Return a loading card whilst AuthContext is loading
      if (isLoadingCardVisible) return <LoadingCard theme='light' />;
      return;
    }

    const roles = getRoles();
    const hasActiveUser = getActiveUser() !== null;

    // Check if the user has the required roles to access the wrapped component
    const userHasRoles = rolesAccess.every((role) => roles.includes(role));

    if (!hasActiveUser) {
      // If the user is not authenticated, redirect them to the login page
      router.push(StaticRoutes.Login);
    } else if (!userHasRoles) {
      // If the user is authenticated but doesn't have the required roles, redirect them to the home page
      router.push(StaticRoutes.Home);
    } else {
      // If the user is authenticated and has the required roles, render the wrapped component
      return <WrappedComponent {...props} />;
    }
  };
}

export default withAuthentication;
